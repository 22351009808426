import { createMachine, assign, send } from '@xstate/compiled';
import { authenticateScreen } from '@shwing/shell-tools';
import io from 'socket.io-client';

const TEMP_ENDPOINT = '';

export interface SocketContext {
  socket?: SocketIOClient.Socket;
  lastError: string;
}

export type SocketEvent = { type: 'CONNECT' };

export const socketMachine = createMachine<SocketContext, SocketEvent, 'socket'>(
  {
    id: 'socket',
    initial: 'notConnected',
    context: {
      socket: undefined,
      lastError: '',
    },
    states: {
      notConnected: {
        initial: 'idle',
        on: {
          CONNECT: [
            {
              cond: 'hasToken',
              target: 'establishingConnection',
            },
            { target: '.noToken' },
          ],
        },
        states: {
          idle: {},
          noToken: {
            invoke: {
              src: 'requestToken',
              onDone: {
                actions: send('CONNECT'),
              },
            },
          },
          error: {
            invoke: {
              src: 'requestToken',
              onDone: {
                actions: send('CONNECT'),
              },
            },
          },
        },
      },
      establishingConnection: {
        invoke: {
          src: 'createSocket',
          onDone: {
            target: 'connected',
            actions: assign({ socket: (_context, event) => event.data as SocketIOClient.Socket }),
          },
          onError: {
            target: 'notConnected.error',
            actions: assign({ lastError: (_context, { data }) => data as string }),
          },
        },
      },
      connected: {},
    },
  },
  {
    services: {
      createSocket: () =>
        new Promise((resolve, reject) => {
          const socket = io(TEMP_ENDPOINT, {
            transports: ['websocket'],
            upgrade: false,
          });

          socket.once('error', reject);
          socket.once('connect', () => resolve(socket));
        }),
      requestToken: () => authenticateScreen(),
    },
    guards: {
      hasToken: () => /\btoken=/.test(document.cookie),
    },
  },
);
