import { createMachine, assign } from '@xstate/compiled';

export interface SessionContext {
  pin: string;
  connectedUsers: unknown[];
  connectedScreens: unknown[];
  applicationUrl: string;
}

export type SessionJoinResponse = SessionContext;

export type SessionEvent =
  | { type: 'SCREEN_AVAILABLE'; pin: string }
  | { type: 'NO_SESSION' }
  | { type: 'LEAVE_SESSION' }
  | {
      type: 'SESSION_JOINED';
      pin: string;
      connectedUsers: unknown[];
      connectedScreens: unknown[];
    }
  | {
      type: 'SESSION_UPDATE';
      pin: string;
      connectedUsers: unknown[];
      connectedScreens: unknown[];
    }
  | { type: 'EXIT_SESSION' }
  | { type: 'LOAD_APPLICATION'; url: string }
  | { type: 'APPLICATION_LOADED' };

export const sessionMachine = createMachine<SessionContext, SessionEvent, 'session'>({
  id: 'session',
  initial: 'rejoiningSession',
  context: {
    pin: '',
    connectedUsers: [],
    connectedScreens: [],
    applicationUrl: '',
  },
  states: {
    rejoiningSession: {
      entry: 'emitRejoinSession',
      on: {
        SESSION_JOINED: {
          target: 'inSession',
          actions: assign({
            pin: (_context, { pin }) => pin,
            connectedUsers: (_context, { connectedUsers }) => connectedUsers,
            connectedScreens: (_context, { connectedScreens }) => connectedScreens,
          }),
        },
        SCREEN_AVAILABLE: {
          target: 'available',
          actions: assign({
            pin: (_context, { pin }) => pin,
          }),
        },
      },
    },
    waitingForPin: {
      on: {
        SCREEN_AVAILABLE: {
          target: 'available',
          actions: assign({
            pin: (_context, { pin }) => pin,
          }),
        },
      },
    },
    available: {
      on: {
        SESSION_JOINED: {
          target: 'inSession',
          actions: assign({
            pin: (_context, { pin }) => pin,
            connectedUsers: (_context, { connectedUsers }) => connectedUsers,
            connectedScreens: (_context, { connectedScreens }) => connectedScreens,
          }),
        },
      },
    },
    inSession: {
      initial: 'idle',
      on: {
        SESSION_UPDATE: {
          actions: assign({
            pin: (_context, { pin }) => pin,
            connectedUsers: (_context, { connectedUsers }) => connectedUsers,
            connectedScreens: (_context, { connectedScreens }) => connectedScreens,
          }),
        },
        LEAVE_SESSION: {
          actions: 'emitLeaveSession',
        },
        EXIT_SESSION: {
          target: 'waitingForPin',
          actions: assign({
            pin: '',
            connectedUsers: [],
            connectedScreens: [],
            applicationUrl: '',
          }),
        },
      },
      states: {
        idle: {
          on: {
            LOAD_APPLICATION: {
              target: 'applicationLoading',
              actions: assign({
                applicationUrl: (_context, { url }) => url,
              }),
            },
          },
        },
        applicationLoading: {
          on: {
            APPLICATION_LOADED: {
              target: 'applicationRunning',
              actions: 'emitApplicationLoaded',
            },
          },
        },
        applicationRunning: {},
      },
    },
  },
});
