import React from 'react';
import { useMachine } from '@xstate/compiled/react';

import { socketMachine } from 'Machines/socket.machine';

import type { InterpreterWithMatches } from '@xstate/compiled';
import type { SocketContext, SocketEvent } from 'Machines/socket.machine';

export interface SocketServiceProviderProps {
  children: React.ReactNode;
}

export const SocketServiceContext = React.createContext<
  InterpreterWithMatches<SocketContext, unknown, SocketEvent, 'socket'> | undefined
>(undefined);

export const SocketServiceConsumer = SocketServiceContext.Consumer;

export function SocketServiceProvider({ children }: SocketServiceProviderProps) {
  const [_state, _send, service] = useMachine(socketMachine, {});
  return <SocketServiceContext.Provider value={service}>{children}</SocketServiceContext.Provider>;
}
