import React, { useContext, useState, useEffect } from 'react';
import { useService } from '@xstate/compiled/react';
import { useCJS } from '@shwing/shell-tools';

import { SocketServiceContext } from 'Contexts/socket';

export interface ApplicationHostProps {
  url: string;
  onLoadComplete: () => void;
}

type UpdateCallback = (data: unknown) => void;

type Application = React.FC<{
  applicationUpdate: (callback: UpdateCallback) => void;
}>;

export function ApplicationHost({ url, onLoadComplete }: ApplicationHostProps) {
  const socketService = useContext(SocketServiceContext);
  const [socketState] = useService(socketService!);
  const [Application, setApplication] = useState<Application | null>(null);
  const loadModule = useCJS(`${url}/screenApp.js`, {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    react: require('react'),
  });

  const [applicationUpdate, setApplicationUpdate] = useState(() => () => {});
  const setApplicationUpdateCallback = (cb: unknown) => setApplicationUpdate(() => cb);

  useEffect(() => {
    loadModule()
      .then((exports) => {
        onLoadComplete();
        setApplication(() => exports.default as React.FC);
      })
      .catch(console.error);
  }, [url]);

  useEffect(() => {
    const applicationUpdateHandler = applicationUpdate;
    socketState.context.socket!.on('APPLICATION_UPDATE', applicationUpdateHandler);

    return () => {
      socketState.context.socket!.off('APPLICATION_UPDATE', applicationUpdateHandler);
    };
  }, [applicationUpdate, socketState.context.socket]);

  if (Application) {
    return <Application applicationUpdate={setApplicationUpdateCallback} />;
  }

  return null;
}
