import React from 'react';
import ReactDOM from 'react-dom';

import { ShellRoot } from 'Components/ShellRoot';
import { SocketServiceProvider } from 'Contexts/socket';

ReactDOM.render(
  <React.StrictMode>
    <SocketServiceProvider>
      <ShellRoot />
    </SocketServiceProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
