import React, { useContext, useEffect } from 'react';
import { useService } from '@xstate/compiled/react';

import 'normalize.css';
import './shellRoot.module.css';

import { SocketServiceContext } from 'Contexts/socket';
import { Session } from 'Components/Session';

// interface UserResponse {
//   name: string;
// }

export function ShellRoot() {
  const socketService = useContext(SocketServiceContext);
  const [socketState, socketSend] = useService(socketService!);

  // Try to connect to socket
  useEffect(() => {
    if (socketState.matches('notConnected.idle')) {
      socketSend({ type: 'CONNECT' });
    }
  }, [socketState, socketSend]);

  // Identify user
  // useEffect(() => {
  //   if (socketState.matches('connected')) {
  //     axios
  //       .get<UserResponse>('/api/user', { withCredentials: true })
  //       .then(({ data }) => setUser(data))
  //       .catch(console.error);
  //   }
  // }, [socketState, setUser]);

  switch (true) {
    case socketState.matches('notConnected.noToken') || socketState.matches('notConnected.error'):
      return <h1>getting token..</h1>;
    case socketState.matches('establishingConnection'):
      return <h1>connecting..</h1>;
    case socketState.matches('connected'):
      return <Session />;
    default:
      return null;
  }
}
