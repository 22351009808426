import React, { useContext, useEffect, useState } from 'react';
import { useMachine, useService } from '@xstate/compiled/react';

import styles from './session.module.css';
// import { UserContext } from 'Contexts/user';
import { SocketServiceContext } from 'Contexts/socket';
import { sessionMachine } from 'Machines/session.machine';
// import { PinEntry } from 'Components/PinEntry';
import { ApplicationHost } from 'Components/ApplicationHost';

import type { SessionJoinResponse } from 'Machines/session.machine';
interface SessionUpdateResponse {
  pin: string;
  connectedUsers: unknown[];
  connectedScreens: unknown[];
}

export function Session() {
  const socketService = useContext(SocketServiceContext);
  const [socketState] = useService(socketService!);
  // const [user] = useContext(UserContext);
  const [sessionState, sessionSend] = useMachine(sessionMachine, {
    actions: {
      emitApplicationLoaded: () => socketState.context.socket!.emit('APPLICATION_LOADED'),
      emitRejoinSession: () => socketState.context.socket!.emit('REJOIN_SESSION'),
      emitLeaveSession: () => socketState.context.socket!.emit('LEAVE_SESSION'),
    },
  });

  useEffect(() => {
    const noSessionHandler = () => sessionSend({ type: 'NO_SESSION' });
    socketState.context.socket!.on('NO_SESSION', noSessionHandler);

    const screenAvailableHandler = (pin: string) => {
      sessionSend({ type: 'SCREEN_AVAILABLE', pin });
    };
    socketState.context.socket!.on('SCREEN_AVAILABLE', screenAvailableHandler);

    const sessionJoinHandler = (data: SessionJoinResponse) => {
      sessionSend({ type: 'SESSION_JOINED', ...data });
    };
    socketState.context.socket!.on('SESSION_JOINED', sessionJoinHandler);

    const sessionUpdateHandler = (data: SessionUpdateResponse) => {
      sessionSend({ type: 'SESSION_UPDATE', ...data });
    };
    socketState.context.socket!.on('SESSION_UPDATE', sessionUpdateHandler);

    const sessionExitHandler = () => sessionSend({ type: 'EXIT_SESSION' });
    socketState.context.socket!.on('EXIT_SESSION', sessionExitHandler);

    interface LoadApplicationPayload {
      url: string;
    }
    const loadApplicationHandler = ({ url }: LoadApplicationPayload) => {
      sessionSend({ type: 'LOAD_APPLICATION', url });
    };
    socketState.context.socket!.on('LOAD_APPLICATION', loadApplicationHandler);

    return () => {
      socketState.context.socket!.off('NO_SESSION', noSessionHandler);
      socketState.context.socket!.off('SCREEN_AVAILABLE', screenAvailableHandler);
      socketState.context.socket!.off('SESSION_JOINED', sessionJoinHandler);
      socketState.context.socket!.off('SESSION_UPDATE', sessionUpdateHandler);
      socketState.context.socket!.off('EXIT_SESSION', sessionExitHandler);
      socketState.context.socket!.off('LOAD_APPLICATION', loadApplicationHandler);
    };
  }, [socketState.context.socket]);

  const [showMenu, setShowMenu] = useState(false);

  const SessionInfo = () => {
    if (showMenu) {
      return (
        <section className={styles.sessionInfo}>
          <ul className={styles.list}>
            <li>
              <strong>Pin: </strong>
              {sessionState.context.pin}
            </li>
            <li>
              <strong>Connected Users: </strong>
              {sessionState.context.connectedUsers.length}
            </li>
            <li>
              <strong>Connected Screens: </strong>
              {sessionState.context.connectedScreens.length}
            </li>
          </ul>
          <div className={styles.buttons}>
            <button type="button" onClick={() => setShowMenu(!showMenu)}>
              Close info
            </button>
          </div>
          <button type="button" onClick={() => sessionSend({ type: 'LEAVE_SESSION' })}>
            Leave session
          </button>
        </section>
      );
    }

    return (
      <button className={styles.sessionToggle} type="button" onClick={() => setShowMenu(!showMenu)}>
        Show info
      </button>
    );
  };

  switch (true) {
    case sessionState.matches('rejoiningSession'):
      return <h1>waiting for pin..</h1>;
    case sessionState.matches('available'):
      return <h1>{sessionState.context.pin}</h1>;
    case sessionState.matches('inSession.idle'):
      return <SessionInfo />;
    case sessionState.matches('inSession.applicationLoading') || sessionState.matches('inSession.applicationRunning'):
      return (
        <>
          <SessionInfo />
          <ApplicationHost
            url={sessionState.context.applicationUrl}
            onLoadComplete={() => sessionSend({ type: 'APPLICATION_LOADED' })}
          />
        </>
      );
    default:
      return null;
  }
}
